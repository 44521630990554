import axios from "axios";
import { config } from "../config/config";

const axiosInstanceJson = axios.create({
  baseURL: config.base.url,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstanceJson.interceptors.request.use(
  (config) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const token = userInfo?.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstanceJson;
