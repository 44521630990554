import { createSlice } from "@reduxjs/toolkit";
import {
  createTracking,
  createUniqName,
  getTrackingId,
  getUniqName,
  searchEmails,
  softDeleteTrackingId,
  undoDeleteTrackingId,
  updateTrackingId,
} from "./TrackingApi";

// Slice for managing master Tracking state
const trackingrSlice = createSlice({
  name: "tracking",
  initialState: {
    tracking: [],
    emaillist: [],
    uniqName: [],
    loading: false,
    error: null,
    dataLimit: 0,
    currentPage: 0,
    totalPages: 0,
    totalTrackingId: 0,
  },
  extraReducers: (builder) => {
    // POST Tracking REDUCERS
    builder.addCase(createTracking.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createTracking.fulfilled, (state, action) => {
      state.tracking.push(action.payload.tracking);
      state.loading = false;
    });
    builder.addCase(createTracking.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // GET Tracking FILTER WISE DATA
    builder.addCase(getTrackingId.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getTrackingId.fulfilled, (state, action) => {
      state.loading = false;
      state.tracking = action.payload.data;
      state.dataLimit = action.payload.meta.limit;
      state.currentPage = action.payload.meta.page;
      state.totalPages = action.payload.meta.totalPages;
      state.totalTrackingId = action.payload.meta.total;
      state.dataFetched = true;
    });
    builder.addCase(getTrackingId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getUniqName.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getUniqName.fulfilled, (state, action) => {
      state.loading = false;
      state.uniqName = action.payload; // Ensure proper payload usage
    });
    builder.addCase(getUniqName.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UPDATE Tracking DATA
    builder.addCase(updateTrackingId.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateTrackingId.fulfilled, (state, action) => {
      const index = state.tracking.findIndex(
        (tracking) => tracking._id === action.payload._id
      );
      if (index !== -1) {
        state.tracking[index] = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(updateTrackingId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // SOFT DELETE VENDOR DATA
    builder.addCase(softDeleteTrackingId.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(softDeleteTrackingId.fulfilled, (state, action) => {
      const deletedId = action.payload.data._id || [];
      state.tracking = state.tracking.filter(
        (tracking) => !deletedId.includes(tracking._id)
      );
      state.loading = false;
    });
    builder.addCase(softDeleteTrackingId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UNDO DELETE VENDOR DATA
    builder.addCase(undoDeleteTrackingId.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(undoDeleteTrackingId.fulfilled, (state, action) => {
      const undodeletedId = action.payload.data._id || [];
      state.tracking = state.tracking.filter(
        (tracking) => !undodeletedId.includes(tracking._id)
      );
      state.loading = false;
    });
    builder.addCase(undoDeleteTrackingId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(createUniqName.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUniqName.fulfilled, (state, action) => {
      // state.tracking.push(action.payload.tracking);
      state.loading = false;
    });
    builder.addCase(createUniqName.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(searchEmails.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(searchEmails.fulfilled, (state, action) => {
      console.log(action.payload, "========action.payload");
      state.loading = false;
      state.emaillist = action.payload;
    });
    builder.addCase(searchEmails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default trackingrSlice.reducer;
