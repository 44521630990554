import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstanceJson";

export const getProduct = createAsyncThunk(
  "product/get",
  async (
    {
      id,
      type,
      status = "All",
      search = "",
      page = 1,
      startDate,
      endDate,
      vendorId,
    },
    thunkAPI
  ) => {
    try {
      const { data } = await axiosInstance.get(
        `/v1/vendor/product/get-product`,
        {
          params: {
            id,
            type,
            status,
            search,
            page,
            limit: 10,
            startDate,
            endDate,
            vendorId,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getProductById = createAsyncThunk(
  "product/getById",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstance.get(
        `/v1/vendor/product/get-singleProduct/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// PUT (Update) Main Category Thunk
export const updateProduct = createAsyncThunk(
  "product/update",
  async (productData, thunkAPI) => {
    try {
      const { data } = await axiosInstance.put(
        `/v1/vendor/product/update-product/${productData._id}`,
        productData
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// DELETE Master Category Thunk
export const softDeleteProduct = createAsyncThunk(
  "product/delete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstance.put(
        `/v1/vendor/product/soft-delete-product/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// UNDO DELETE Master Category Thunk
export const undoDeleteProduct = createAsyncThunk(
  "product/undoDelete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstance.put(
        `/v1/vendor/product/undo-delete-product/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Define an async thunk to soft delete categories
export const softDeleteProducts = createAsyncThunk(
  "products/softDeleteProduct",
  async (ids, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/v1/vendor/product/delete-selected-data`,
        {
          ids,
        }
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getVendor = createAsyncThunk(
  "mainCategory/get",
  async ({ vendorId }, thunkAPI) => {
    try {
      const { data } = await axiosInstance.get(
        `/v1/vendor/general/get-vendor/${vendorId}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAllProduct = createAsyncThunk(
  "product/getAllProduct",
  async (thunkAPI) => {
    try {
      const { data } = await axiosInstance.get(
        `/v1/vendor/product/get-allproducts`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
