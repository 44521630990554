import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstanceJson from "../../../api/axiosInstanceJson";

// POST Tracking DATA
export const createTracking = createAsyncThunk(
  "Tracking/create",
  async (trackingdata, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.post(
        `/v1/admin/tracking/create-tracking`,
        trackingdata
      );
      return data;
    } catch (error) {
      // Handle edge cases for error response
      const message =
        error.response?.data?.message ||
        error.message ||
        "Unknown error occurred";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// GET Tracking DATA FILTER WISE
export const getTrackingId = createAsyncThunk(
  "Tracking/getAll",
  async (
    {
      status = "All",
      search = "",
      page = 1,
      limit = 10,
      startDate,
      endDate,
      paginate,
    },
    thunkAPI
  ) => {
    try {
      const { data } = await axiosInstanceJson.get(
        `/v1/admin/tracking/get-trackingId`,
        {
          params: {
            status,
            search,
            page,
            limit,
            startDate,
            endDate,
            paginate,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateTrackingId = createAsyncThunk(
  "Tracking/update",
  async (authData, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.put(
        `/v1/admin/tracking/update-trackingId/${authData._id}`,
        authData
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET VENDOR BY ID
export const getTrackingById = createAsyncThunk(
  "Tracking/getById",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.get(
        `/v1/admin/tracking/get-singletrackingId/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// SOFT DELETE VENDOR DATA
export const softDeleteTrackingId = createAsyncThunk(
  "Tracking/delete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.put(
        `/v1/admin/tracking/softdelete-trackingId/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// UNDO DELETE VENDOR DATA
export const undoDeleteTrackingId = createAsyncThunk(
  "Tracking/undoDelete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.put(
        `/v1/admin/tracking/undodelete-trackingId/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getUniqName = createAsyncThunk(
  "Tracking/getUniqName", // Unique action type
  async ({ search = "" }, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.get(
        `/v1/user/get-uniqname-list`,
        {
          params: { search },
        }
      );
      return data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Failed to fetch uniq names"
      );
    }
  }
);

//  Create UniqName
export const createUniqName = createAsyncThunk(
  "Tracking/createUniqName",
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstanceJson.post(
        `/v1/user/create-uniqname`,
        data
      );
      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.message ||
        "Unknown error occurred";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const searchEmails = createAsyncThunk(
  "user/searchEmails",
  async (query, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceJson.get(`/v1/user/search-emails`, {
        params: { query },
      });

      console.log(response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
