import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
import axiosInstancejson from "../../../api/axiosInstanceJson";

// GET SUB_CATEGORY DATA FILTER WISE
export const getQuotations = createAsyncThunk(
  "subCategory/getAll",
  async (
    {
      id,
      status = "All",
      search = "",
      page = 1,
      limit = 10,
      startDate,
      endDate,
      paginate,
    },
    thunkAPI
  ) => {
    try {
      const { data } = await axiosInstance.get(
        `/v1/admin/quotation/get-admin-quotation`,
        {
          params: {
            id,
            status,
            search,
            page,
            limit,
            startDate,
            endDate,
            paginate,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateQuotations = createAsyncThunk(
  "quotations/updateQuotations",
  async ({ quotationsId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/v1/admin/quotation/accept-quotation/${quotationsId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const closeQuotations = createAsyncThunk(
  "quotations/updateQuotations",
  async ({ quotationsId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/v1/admin/quotation/close-quotation/${quotationsId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
