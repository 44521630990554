// src/App.js
import React, { Suspense, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "./layouts/Sidebar";
import Router from "./routes/router";
import useAuthToken from "./Hook/AuthToken/authToken";
import Loader from "./Loader/Loader";

function App() {
  useAuthToken();

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const shouldRenderNavbar = ["/login"].includes(pathname);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const token = storedUser?.token;

    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div className="flex h-screen bg-gray-200">
      {!shouldRenderNavbar && (
        <div className="flex">
          <SideBar />
        </div>
      )}

      <div className="w-full h-screen overflow-scroll scrollbar-hide">
        <Suspense fallback={<Loader />}>
          <Router />
        </Suspense>
      </div>
    </div>
  );
}

export default App;
