import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";

// POST MAIN_CATEGORY DATA
export const createBanner = createAsyncThunk(
  "banner/create",
  async (bannerdata, thunkAPI) => {
    try {
      const { data } = await axiosInstance.post(
        `/v1/admin/banner/createBanner`,
        bannerdata
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET MAIN_CATEGORY DATA FILTER WISE
export const getBanner = createAsyncThunk(
  "banner/getAll",
  async (
    {
      id,
      status = "All",
      search = "",
      page = 1,
      limit = 10,
      startDate,
      endDate,
      paginate,
    },
    thunkAPI
  ) => {
    try {
      const { data } = await axiosInstance.get(`/v1/admin/banner/getBanner`, {
        params: {
          id,
          status,
          search,
          page,
          limit,
          startDate,
          endDate,
          paginate,
        },
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// UPDATE MAIN_CATEGORY DATA
export const updateBanner = createAsyncThunk(
  "banner/update",
  async (authData, thunkAPI) => {
    try {
      const { data } = await axiosInstance.put(
        `/v1/admin/banner/updateBanner/${authData._id}`,
        authData
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET MASTER_CATEGORY BY ID
export const getBannerById = createAsyncThunk(
  "masterCategory/getById",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstance.get(
        `/v1/admin/banner/getSingleBanner/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// SOFT DELETE MASTER_CATEGORY DATA
export const deleteBanner = createAsyncThunk(
  "banner/delete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstance.delete(
        `/v1/admin/banner/delete-banner/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
