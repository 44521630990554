import { createSlice } from "@reduxjs/toolkit";
import {
  createBanner,
  deleteBanner,
  getBanner,
  updateBanner,
} from "./bannerApi";

// Slice for managing master category state
const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    banner: [],
    loading: false,
    error: null,
    dataLimit: 0,
    currentPage: 0,
    totalPages: 0,
    totalBanner: 0,
  },
  extraReducers: (builder) => {
    // POST MAIN_CATEGORY REDUCERS
    builder.addCase(createBanner.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createBanner.fulfilled, (state, action) => {
      state.banner.push(action.payload);
      state.loading = false;
    });
    builder.addCase(createBanner.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // GET MAIN_CATEGORY FILTER WISE DATA
    builder.addCase(getBanner.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getBanner.fulfilled, (state, action) => {
      state.loading = false;
      state.banner = action.payload.data;
      state.dataLimit = action.payload.meta.limit;
      state.currentPage = action.payload.meta.page;
      state.totalPages = action.payload.meta.totalPages;
      state.totalBanner = action.payload.meta.total;
      state.dataFetched = true;
    });
    builder.addCase(getBanner.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UPDATE MAIN_CATEGORY DATA
    builder.addCase(updateBanner.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateBanner.fulfilled, (state, action) => {
      const index = state.banner.findIndex(
        (banner) => banner._id === action.payload._id
      );
      if (index !== -1) {
        state.banner[index] = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(updateBanner.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // SOFT DELETE Banner DATA
    builder.addCase(deleteBanner.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteBanner.fulfilled, (state, action) => {
      const deletedId = action.payload.data._id || [];
      state.banner = state.banner.filter(
        (banner) => !deletedId.includes(banner._id)
      );
      state.loading = false;
    });
    builder.addCase(deleteBanner.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default bannerSlice.reducer;
